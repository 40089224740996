.createInterventionForm {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: 20px 40px;
  border: 3px solid var(--color-info);
  border-radius: var(--border-radius);
  width: min-content;
  margin: 10px;
}
