/* NO-EDIT */
.sessionInfoCardContainer-noEdit {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-xl);
}

.sessionInfoCardHeader-noEdit {
  margin-bottom: var(--spacing-xl);
}

.sessionInfoCardContentRow-noEdit {
  display: grid;
  grid-template-columns: minmax(250px, 0.5fr) repeat(2, 1fr) minmax(160px, 0.5fr);
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.sessionInfoCardItem-noEdit {
  display: flex;
  gap: var(--spacing-sm);
}

/* CAN-EDIT & NO-EDITING (STARTED || STARTING)*/
.sessionInfoCardContainer-started {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  margin-top: var(--spacing-xl);
}

.sessionInfoCardButtonsBlock-started {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.sessionInfoCardHeader-started {
  margin-bottom: var(--spacing-sm);
}

.sessionInfoCardContentRow-started {
  display: grid;
  /*grid-template-columns: repeat(2, minmax(200px, 1fr)) minmax(125px, 0.5fr) minmax(275px, 1fr);*/
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: var(--spacing-xl);
}

.sessionInfoCardColoredText-started {
  color: var(--color-primary);
}

.sessionInfoCardItem-right-started {
  justify-self: end;
}

/* CAN-EDIT & NO-EDITING (CREATED || SCHEDULED)*/
.sessionInfoCardContainer {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-md);
}

.sessionInfoCardDataBlock {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  width: 70%;
}

.sessionInfoCardButtonsBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 275px;
}

.sessionInfoCardHeader {
  display: flex;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-sm);
}

.sessionInfoCardContentRow {
  display: grid;
  column-gap: var(--spacing-xl);
  grid-template-columns: repeat(2, minmax(200px, 1fr)) minmax(125px, 0.5fr);
}

.sessionInfoCheckboxField {
  display: flex;
  gap: var(--spacing-sm);
}
.buttonHeaderContainer {
  display: flex;
  justify-content: space-between;
}
