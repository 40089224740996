.offsetInitTimeContainer {
  display: grid;
  grid-template-columns: 125px 200px 125px 1fr;
  padding: var(--spacing-md) 0;
  column-gap: var(--spacing-md);
  align-items: center;
}
.offsetInitTooltip {
  display: grid;
  grid-template-columns: 145px auto;
  gap: 10px;
  align-items: center;
}

.offsetInitInput {
  border: 0.1rem solid grey;
  height: 2rem;
}
