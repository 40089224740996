.agendaItemContainer {
  display: grid;
  grid-template-columns: 20px 80px 1fr 180px;
  border-bottom: 0.5px solid rgb(201, 201, 201);
}
.agendaItemContainerWithoutSort {
  grid-template-columns: 20px 1fr 180px;
  min-height: 44px;
}

.upDownButtonsContainer {
  display: flex;
  width: 80px;
}

.agendaItemEditDeleteButtonsContainer {
  display: flex;
  width: 180px;
}

.agendaItemTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: var(--spacing-md);
  padding-right: var(--spacing-md);
  padding-bottom: var(--spacing-sm);
  padding-top: var(--spacing-xs)
}

.agendaItemPointer {
  cursor: pointer;
}

.agendaActiveItem {
  background-color: rgba(81, 255, 0, 0.2);
  color: rgb(0, 125, 0);
  font-weight: 700;
}

.startTime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.orderPosition {
  display: flex;
  align-items: center;
}
.unnamedPoint {
  font-style: italic;
  color: rgba(243, 87, 87, 0.75);
}
.disabledPointer {
  cursor: default;
}
