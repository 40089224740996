.editIntervention {
  display: flex;
  gap: var(--spacing-xl);
}
.downloadText {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
  margin-left: -38px;
}
.downloadLine {
  border-bottom: 0.1rem solid grey;
}
.downloadButton {
  display: flex;
  gap: var(--spacing-md);
  height: 2.7rem;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
