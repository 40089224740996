.timeOverlay {
  position: absolute;
  top: 2px;
  left: 10px;
  font-size: 1.4vw; 
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.5vw 0.5vw; 
  border-radius: 5px;
  font-weight: bold;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Courier New', Courier, monospace; 
  box-sizing: border-box;
  white-space: nowrap; 
}

.timeContainer {
  display: flex;
  align-items: center;
}

.timeSegment {
  width: 1.8vw; 
  text-align: center;
  flex: none;
}

.separator {
  width: 0.6vw; 
  text-align: center;
  flex: none;
}

