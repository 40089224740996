.agendaConfigurationCardContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.agendaConfigurationCardDataBlock {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  width: 70%;
}

.agendaRow {
  padding-left: 1rem;
}

.agendaConfigurationCardButtonsBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.agendaConfigurationCardHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.agendaConfigurationCardFooter {
  display: flex;
  justify-content: center;
}

.agendaActiveItem {
  background-color: rgba(81, 255, 0, 0.2);
  color: rgb(0, 125, 0);
  font-weight: 700;
}

.agendaButtonHide {
  display: none;
}

.agendaSelectedItem {
  background-color: lightgrey;
  font-weight: 700;
}

.headerButtons {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-sd);
}

.tooltipWrapper {
  display: inline-block;
}
