.rootPlenary {
  top: 0;
  z-index: 2;
  position: relative;
  background-color: var(--color-primary);
}

.title {
  flex-grow: 1;
}

.iconLanguage svg {
  width: 20px;
  height: 20px;
  padding-right: 3px;
  padding-top: 3px;
}

.appBarPlenary {
  background-color: black;
}

.flex {
  flex: 1;
}

.toolbarPlenary {
  color: black;
  background-color: var(--color-primary);
  padding: var(--spacing-xl);
  display: flex;
  justify-content: space-between;
}

.toolbarPlenary>img {
  max-height: 150px;
  max-width: 220px;
  display: flex;
  justify-content: space-between;
}

.imageHeader {
  width: 217px;
}

.menuButton {
  position: absolute;
  right: 0;
  margin-left: -12px;
  margin-right: 20px;
  cursor: pointer;
}

.languageButton {
  padding-right: 65px;
}

.userActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* Alinea los elementos al final */
  gap: 8px;
  /* Ajusta el espacio entre los elementos */
}

.userName {
  color: var(--color-light);
  font-weight: bold;
  padding-right: 14px;
  
}