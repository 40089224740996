@font-face {
  font-family: "Poppins-Light";
  src: url("./resources/fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("./resources/fonts/Poppins-Regular.ttf") format("truetype");
}

:root {
  /* Colores */
  /*--color-primary: #e30613;
  --color-secondary: #e0e0e0;*/

  --color-success: #689f38;
  --color-warning: #ffd823;
  --colo-medium: #e36a28;
  --color-danger: #ff001b;
  --color-info: #17a2b8;
  --color-light: #fff;
  --color-dark: #002947;
  --color-border: #e4f0f8;
  --color-background: #fff;
  --color-background-videoplayercontrols: #3f414a;
  --color-text: #707070;
  --color-title: #36333a;
  --color-select-row: rgba(29, 29, 27, 0.08);
  --color-background-selected-alpha: rgba(23, 162, 184, 0.1);
  --color-button-disabled: #b8b7b7;

  /* Tipografía */
  --font-family: "Poppins-Light", Arial, sans-serif;
  --font-family-titles: "Poppins-Regular", Arial, sans-serif;
  --font-size: 16px;
  --font-size-subtitle: 12px;
  --line-height: 1.5;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --font-size-little: 0.8rem;

  /* Espaciado */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-xxl: 3rem;

  /* Bordes */
  --border-radius: 1rem;
  --border-radius-button: 0.5rem;
  --border-radius-input: 4px;
  --border-radius-tag: 0.5rem 5rem 5rem 0.5rem;
  --border-radius-timeline: 10px;
  --border-width: 1px;
  --border-width-button: 2px;
  --border: var(--border-width) solid var(--color-secondary);
  --border-card: var(--border-width) solid var(--color-border);

  /*Breakpoints*/
  --break_points-xs: 0;
  --break_points-sm: 600px;
  --break_points-md: 960px;
  --break_points-lg: 1280px;
  --break_points-xl: 1920px;

  /* Otros */
  --box-shadow: 0px 2px 32px #0000000a;
}

#tiivii {
  --color-primary: #e30613;
  --color-secondary: #e0e0e0;
}

#torrevieja {
  --color-primary: #006298;
  --color-secondary: #e0e0e0;
}

#menorca {
  --color-primary: #f2b735;
  --color-secondary: #e0e0e0;
}

#ciutadella {
  --color-primary: #ee042c;
  --color-secondary: #e0e0e0;
  --font-family: "Segoe-UI", Arial, sans-serif;
  --font-family-titles: "Segoe-UI", Arial, sans-serif;
}

#alaior {
  --color-primary: #0a4a8b;
  --color-secondary: #e0e0e0;
  --font-family: "Segoe-UI", Arial, sans-serif;
  --font-family-titles: "Segoe-UI", Arial, sans-serif;
}

#escastell {
  --color-primary: #f8c300;
  --color-secondary: #e0e0e0;
  --font-family: "Segoe-UI", Arial, sans-serif;
  --font-family-titles: "Segoe-UI", Arial, sans-serif;
}

#ferreries {
  --color-primary: #e37d02;
  --color-secondary: #e0e0e0;
  --font-family: "Segoe-UI", Arial, sans-serif;
  --font-family-titles: "Segoe-UI", Arial, sans-serif;
}

#consell {
  --color-primary: #79cff3;
  --color-secondary: #e0e0e0;
  --font-family: "Roboto", Arial, sans-serif;
  --font-family-titles: "Roboto", Arial, sans-serif;
}

#default {
  --color-primary: #0a4a8b;
  --color-secondary: #e0e0e0;
  --font-family: "Segoe-UI", Arial, sans-serif;
  --font-family-titles: "Segoe-UI", Arial, sans-serif;
}

/* Normalize CSS */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: var(--font-family) !important;
}

body {
  margin: 0;
  height: 100vh;
  overflow-x: hidden;
  font-size: var(--font-size);
  line-height: var(--line-height);
  color: var(--color-text);
  background-color: var(--color-background);
}

[data-tema-oscuro="true"] {
  --color-fondo: #1e2936;
  --color-text: #fff;
  --color-background: #253649;
  --color-border: #2b3e54;
  --filter-image: brightness(0) invert(1);
  --border-card: 1px solid var(--color-border);
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ff5800;
  border-radius: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
i,
b {
  color: var(--color-title);
  margin: 0;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-titles);
}

h1 {
  font-size: 1.75rem;
}
h2 {
  line-height: 1em;
}
h3 {
  font-size: var(--font-size);
  font-weight: var(--font-weight-normal);
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
label {
  color: var(--color-title);
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background: none;
  font-family: var(--font-family);
  font-size: inherit;
  line-height: var(--line-height);
  cursor: pointer;
}

input,
textarea,
select {
  border: none;
  outline: none;
  line-height: var(--line-height);
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: var(--font-family) !important;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-fondo);
}

.active svg {
  fill: var(--color-primary) !important;
}

.active {
  color: var(--color-primary) !important;
}

input {
  max-width: 100%;
}
