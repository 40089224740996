.tableContainer {
  overflow: auto;
  max-height: 60vh;
}

.tableEmpty {
  display: flex;
  overflow: hidden;
}

.buttonsContainer {
  display: flex;
  gap: var(--spacing-xl);
  margin-top: var(--spacing-xl);
}

.rowSelected {
  background-color: var(--color-select-row);
}

.rowMember {
  cursor: pointer;
}
