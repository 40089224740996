.layerTranscriptions {
    position: absolute;
    top: var(--spacing-md);
    left: 0;
    width: 100%;
    min-height: 350px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: var(--border-radius-button);
}
.layerText {
    color: white;
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7), 0 0 15px rgba(0, 0, 0, 0.3);
}