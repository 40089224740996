
.videoAreaContainer {
  margin: var(--spacing-xl);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
  min-height: 350px;
  padding: var(--spacing-md);
  }
  
  .videoAreaContainer.hovered {
  border: 4px dashed #8cc5dc; /* Color y grosor del borde al pasar el mouse sobre el Paper */
  }
  
  .noVideo {
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  
  .videoControls {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  }
  
  .downloadHeader {
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
  }
  
  .downloadButtons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  }
  
  .downloadSection {
  margin-bottom: 10px;
  text-align: center;
  }