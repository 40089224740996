.timerCellStyle {
  width: 3.4rem;
  height: 100%;
  border-radius: 0.5rem;
  padding: var(--spacing-xs);
  padding-left: var(--spacing-sm);
  -webkit-box-shadow: inset 0rem 0rem 1rem -0.5rem rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0rem 0rem 1rem -0.5rem rgba(0, 0, 0, 0.75);
  box-shadow: inset 0rem 0rem 1rem -0.6rem rgba(0, 0, 0, 0.75);
}
.isCreating {
  display: flex;
  gap: var(--spacing-md);
  width: fit-content;
}
.isNotCreating {
  display: flex;
  gap: var(--spacing-md);
}
.hourButton {
  width: 8rem;
}
.inputs {
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
}

.timerStyle {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  gap: 5px;
}
