.microSelector {
  min-width: 150px;
}
.boldText {
  font-weight: bold !important;
}
.rightBorder {
  border-right: 1px solid #ccc;
}
.centeredCheckbox {
  display: flex;
  justify-content: center;
  padding-left: 80px;
  border-right: 1px solid;
  
  }
  .checkboxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  }