.footer {
  height: 70px;
  bottom: 0;
  background-color: black;
  color: white;
  width: 100%;
}
.footer > a {
  color: white;
  text-decoration: none;
}
.logoFooter {
  height: 70px;
  display: flex;
  align-items: center;
}
.logoFooter img {
  max-height: 34px;
  max-width: 137px;
}
.legalText {
  text-align: right;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
