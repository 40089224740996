.participantsButtonsContainer {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-xl);
  padding: 30px;
  padding-right: 0;
}

.buttonAddParticipantContainer {
  margin-left: 5px;
}

.saveButtonContainer {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
.centeredRedText {
  color: red;
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
