/* Button.module.css */
.button {
  border-radius: var(--border-radius-button);
  -webkit-border-radius: var(--border-radius-button);
  -moz-border-radius: var(--border-radius-button);
  -ms-border-radius: var(--border-radius-button);
  -o-border-radius: var(--border-radius-button);
  border: 2px solid var(--color-primary);
  padding: 0.5rem 1rem;
  width: 100%;
  color: var(--color-light);
  font-weight: var(--font-weight);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: var(--spacing-sm);
}
.text {
  padding-top: 9px;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  color: var(--color-info);
  height: 44px;
  width: 8%;
  justify-content: left;
  padding-left: 0;
}
.text-disable {
  padding-top: 9px;
  text-align: left;
  background-color: transparent;
  border-color: transparent;
  color: var(--color-text);
  height: 44px;
  width: 8%;
  pointer-events: none;
}
.primary {
  background-color: var(--color-primary);
}
.secondary {
  background-color: transparent;
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.dark {
  background-color: var(--color-dark);
  border-color: var(--color-dark);
  color: var(--color-light);
}
.light {
  background-color: var(--color-light);
  border-color: var(--color-light);
  color: var(--color-dark);
}
.danger {
  background-color: var(--color-light);
  border-color: var(--color-danger);
  color: var(--color-danger);
}
.primary_limitWith {
  max-width: 200px;
  background-color: var(--color-primary);
  margin-top: 1rem;
}
.disable {
  pointer-events: none;
  background-color: var(--color-button-disabled);
  border-color: var(--color-button-disabled);
  color: var(--color-background);
}

.size-fitContent {
  width: fit-content;
}

.danger-fill {
  background-color: var(--color-danger);
  border: 0;
  color: var(--color-light);
}

.icon {
  fill: currentColor;
}

.iconDisable {
  fill: var(--color-light);
}
