.table {
  border-radius: 12px !important;
  width: 100%;
  color: var(--color-text) !important;
  background-color: var(--color-background) !important;
}

.tableBody {
  position: relative;
}

.noResultsContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  padding-top: 20rem;
}

.header {
  display: flex;
}

.tableHeader {
  color: var(--color-text) !important;
  border-bottom: var(--border-card) !important;
}

.tableCell {
  color: var(--color-text) !important;
  border-bottom: var(--border-card) !important;
}

.secondaryRow {
  border-bottom: 0;
  outline: 0;
}

.paginationBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md) var(--spacing-xl);
  color: var(--color-text) !important;
}

.row {
  border-radius: 0;
  box-shadow: none;
}

.statusColumnHeader {
  text-align: center;
}

.statusColumnText {
  text-align: center;
}
.statusColumnColorText-scheduled {
  border-radius: var(--border-radius-input);
  color: rgb(56, 179, 255);
  background-color: rgba(133, 205, 250, 0.5);
}
.statusColumnColorText-started {
  border-radius: var(--border-radius-input);
  color: rgb(255, 56, 56);
  background-color: rgba(255, 56, 56, 0.5);
}

.redirectLink {
  color: var(--color-info);
}

.actionCell {
  display: flex;
  align-items: center;
}

.openInNewIcon {
  cursor: pointer;
  margin-left: 10px;
}