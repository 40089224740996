.pageContainer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
}

.typographyMarginBottom {
    margin-bottom: var(--spacing-xl);
}

.centeredRedText {
    color: red;
    margin-top: 34px;
    text-align: center;
}

.headerPageContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 0 var(--spacing-xl);*/
    width: 100%;
}

.filterGroupContainer {
    display: flex;
    gap: var(--spacing-xl);
    justify-content: left;
    width: 100%;
    margin-top: var(--spacing-xl);
}

.buttonContainer {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 40%;
    gap: 40px;
    margin-top: 21px;
}

.table {
    border-radius: 12px !important;
    width: 100%;
    color: var(--color-text) !important;
    background-color: var(--color-background) !important;
    padding: var(--spacing-md) var(--spacing-xl) var(--spacing-sm) var(--spacing-xl);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    gap: var(--spacing-xl);
}