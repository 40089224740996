.buttonsContainer {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-md);
  margin-top: var(--spacing-xl);
}
.obligation {
  color: red;
}
.typographyMarginBottom {
  margin-bottom: var(--spacing-md);
}
.typographyMarginBottom {
  margin-bottom: var(--spacing-md);
}

.centeredRedText {
  color: red;
  margin-top: 20px;
  text-align: center;
}
