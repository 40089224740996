.imageField {
  display: flex;
  gap: var(--spacing-xl);
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: var(--spacing-xl);
  margin-top: var(--spacing-xl);
}

.boldText {
  font-weight: bold !important;
}
.typographyMarginBottom {
  margin-bottom: var(--spacing-xl);
}

.centeredRedText {
  color: red;
  margin-top: 20px;
  text-align: center;
}
