.inDirectContainer {
  display: flex;
  flex-direction: column;
}

.inDirectBottomArea {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.areaContainer {
  margin: var(--spacing-xl);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 350px;
  padding-bottom: inherit;
  padding-right: inherit;
  padding-left: inherit;
}
