.statusChange {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.statusChangeText {
  text-align: center;
}

.statusChangeNotPossible {
  color: red;
}

.statusChangeButton {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
