.plenaryListPageContainer {
    /*padding: var(--spacing-xl) 6rem;*/
}

.pageContainer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
}

.headerPageContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 0 var(--spacing-xl);*/
    width: 100%;
}

.filterGroupContainer {
    display: flex;
    gap: var(--spacing-xl);
    justify-content: left;
    width: 100%;
}

.buttonContainer {
    display: flex;
    gap: var(--spacing-xl);
}

.table {
    border-radius: 12px !important;
    width: 100%;
    color: var(--color-text) !important;
    background-color: var(--color-background) !important;
    padding: var(--spacing-md) var(--spacing-xl) var(--spacing-sm) var(--spacing-xl);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    gap: var(--spacing-xl);
}