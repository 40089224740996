.container {
  padding: var(--spacing-md);
  box-shadow: var(--box-shadow);
  width: 600px;
  border-radius: var(--border-radius);
  display: grid;
  gap: var(--spacing-md);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.over {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  height: 100vh;
  width: 100vw;
  z-index: 3;
}

.header {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.close:hover {
  fill: black;
  cursor: pointer;
}
.body {
  text-align: center;
  padding-top: 1rem;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.iconButton {
  width: 12rem;
  padding:1rem;
}
.iconButtonAccept {
  width: 20rem;
  padding:1rem;
}