.secondary {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: var(--border-radius-input);
  -webkit-border-radius: var(--border-radius-input);
  -moz-border-radius: var(--border-radius-input);
  -ms-border-radius: var(--border-radius-input);
  -o-border-radius: var(--border-radius-input);
}

.select-variant-border {
  min-width: 200px;
  height: 56px;
  padding: var(--spacing-md);
  background-color: #fff;
  border: 1px solid #c1c1c1;
  border-radius: var(--border-radius-input);
  -webkit-border-radius: var(--border-radius-input);
  -moz-border-radius: var(--border-radius-input);
  -ms-border-radius: var(--border-radius-input);
  -o-border-radius: var(--border-radius-input);
}
