.page {
  /*padding-top: 4rem;
  padding-left: 7rem;*/
}

.title {
  padding-bottom: 2rem;
  /*padding-left: 2rem;*/
}

.card {
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  margin-right: 110px;
  margin-bottom: 2rem;
}

.typography {
  padding-left: 10px;
}

.firstLine {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  gap: 60px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.formControlLabel {
  align-self: center;
  font-family: var(--font-family);
}

.formControlLabelEdit {
  margin-left: 20rem;
}

.secondLine {
  display: flex;
  margin-bottom: 30px;
  padding-left: 0 !important;
  justify-content: left;
  gap: 20px;
}

.thirdLine {
  display: grid;
  grid-template-columns: 2fr 2fr 4fr;
  padding-bottom: 40px;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.divider {
  height: 1px;
  background-color: red;
  width: 300px;
  justify-content: center;
}

.space {
  padding-top: 30px;
}

.oneInput {
  max-width: 750px;
}

.data {
  font-family: var(--font-family);
}

.buttonBack svg {
  width: 20px;
  height: 19px;
}

.buttonBack {
  text-align: left;
}

.buttonCreatePlenary {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.buttonCancelEditPlenary {
  width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40%;
  gap: 40px;
  margin-top: 21px;
}

.typographyMarginBottom {
  margin-bottom: var(--spacing-xl);
}

.centeredRedText {
  color: red;
  margin-top: 34px;
  text-align: center;
}

.messageURLVideo {
  color: red;
  padding-left: 10px;
  padding-top: 8px;
  font-weight: bold;
}