/*.container {
  /*padding: 20px;
}*/

/*.pointDayContainer {
  background: #736262;
  display: flex;
  grid-template-columns: 1fr 1fr 4fr;
  width: 100%;
}*/
.accordionSummary {
  border: 0.1rem solid var(--color-primary);
  border-radius: var(--border-radius);
  width: 100%;
  padding: var(--spacing-md);
}
.editPointOfDay {
  display: flex;
  width: 100%;
  gap: var(--spacing-xl);
  margin-top: 5px;
}

/*.pointDayDescription {
  width: 33%;
  flex-shrink: 0;
}*/

.pointDayAccordionUl {
  padding: 0;
  list-style: none;
}

.pointDayAccordionScroll {
  max-height: 300px;
  overflow-y: auto;
}

/*.pointDayAccordionLi {
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}*/

.pointDayAccordionLi {
  border-bottom: 0.1rem solid #e7dede;
  display: flex;
  gap: var(--spacing-md);
  flex-direction: column;
  padding: 0.7rem 0 0.7rem var(--spacing-xxl);
  width: 100%;
  background-color: white;
}
.selectIntervention {
  background-color: var(--color-background-selected-alpha);
}
/*.interventionName {
}*/
.editIntervention {
  display: flex;
  width: 100%;
  gap: var(--spacing-xl);
  align-items: flex-end;
}
.interventionButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.assignTranscriptions {
  width: 100%;
  margin: var(--spacing-md) 0 var(--spacing-xl) 0;
}
.alignRight {
  margin-left: 880px;
  margin-top: -44px;
  font-size: 0.8rem; 
}
